<template>
  <div class="menu-container">
    <h1>高空防御</h1>
    <div class="menu-buttons">
      <button @click="startGame('single')">单人游戏</button>
      <button @click="startGame('multi')" v-if="!isMobile">双人游戏</button>
    </div>
    <div class="instructions">
      <h2>游戏说明</h2>
      <div v-if="!isMobile" class="instruction-text">
        <p>玩家1：方向键上下控制炮台，"/"键发射，"H"切换子弹类型，","增加子弹速度，"M"减少子弹速度</p>
        <p>玩家2：W/S控制炮台，"D"键发射，"G"切换子弹类型，"Q"增加子弹速度，"E"减少子弹速度</p>
        <p>初始设置：100发炮弹总数，每5秒自动增加一发炮弹</p>
        <p>切换为智能炮弹后，炮弹会自动攻击最近的飞机，但是得分减半</p>
      </div>
      <div v-else class="instruction-text">
        <p>使用屏幕上的按钮控制炮台和发射子弹，请注意摇杆的方向与炮台实际方向相反，使用时请按住摇杆挪动</p>
        <p>初始设置：100发炮弹总数，每5秒自动增加一发炮弹</p>
        <p>切换为智能炮弹后，炮弹会自动攻击最近的飞机，但是得分减半</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    startGame(mode) {
      this.$router.push({ name: 'Game', query: { mode } })
    },
    checkMobile() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  },
  mounted() {
    this.checkMobile()
  }
}
</script>

<style scoped>
.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

h1 {
  font-size: 3em;
  margin-bottom: 30px;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  font-size: 1.2em;
  padding: 10px 20px;
  margin: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.instructions {
  margin-top: 30px;
  text-align: center;
  max-width: 600px;
}

.instruction-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.instruction-text p {
  margin-bottom: 10px;
}
</style>
</style>