import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    highScores: []
  },
  mutations: {
    updateHighScores(state, scores) {
      state.highScores = scores
    }
  },
  actions: {
    saveHighScores({ commit }, scores) {
      // 这里可以添加将高分保存到后端的逻辑
      commit('updateHighScores', scores)
    }
  },
  getters: {
    topHighScores: state => state.highScores.slice(0, 10)
  }
})