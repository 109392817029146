<template>
  <div class="bullet" :style="bulletStyle">
    <img :src="bulletImage" :alt="bulletType" />
  </div>
</template>

<script>
import bulletImage from '@/assets/bullet.png'
import smartBulletImage from '@/assets/smart-bullet.png'

export default {
  name: 'Bullet',
  props: ['bullet'],
  computed: {
    bulletStyle() {
      return {
        left: `${this.bullet.x}px`,
        top: `${this.bullet.y}px`,
        transform: `rotate(${this.bullet.angle}deg)`,
      }
    },
    bulletImage() {
      // 使用导入的图像，而不是字符串路径
      return this.bullet.type === 'smart' ? smartBulletImage : bulletImage
    },
    bulletType() {
      return this.bullet.type
    }
  },
}
</script>

<style scoped>
.bullet {
  position: absolute;
  width: 15px;
  height: 15px;
}
.bullet img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>