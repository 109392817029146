<template>
  <div class="score-board">
    <h2>得分板</h2>
    <div v-for="(player, index) in players" :key="index" class="player-score">
      <h3>玩家 {{ index + 1 }}</h3>
      <p>得分: {{ player.score }}</p>
      <p>最高分: {{ player.highScore }}</p>
    </div>
    <button @click="$emit('restart')">重新开始</button>
  </div>
</template>

<script>
export default {
  name: 'ScoreBoard',
  props: ['players'],
}
</script>

<style scoped>
.score-board {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.player-score {
  margin-bottom: 20px;
}
</style>