<template>
  <div class="plane" :style="planeStyle">
    <img :src="planeImage" :alt="planeType" :style="{ transform: `scaleX(${plane.direction === 'left' ? 1 : -1})` }" />
  </div>
</template>

<script>
import plane1 from '@/assets/plane1.png'
import plane2 from '@/assets/plane2.png'
import plane3 from '@/assets/plane3.png'

export default {
  name: 'Plane',
  props: ['plane'],
  computed: {
    planeStyle() {
      return {
        left: `${this.plane.x}px`,
        top: `${this.plane.y}px`,
      }
    },
    planeImage() {
      switch (this.plane.type) {
        case 'fast': return plane1
        case 'medium': return plane2
        case 'slow': return plane3
        default: return plane1
      }
    },
    planeType() {
      return this.plane.type
    }
  },
}
</script>

<style scoped>
.plane {
  position: absolute;
  width: 50px;
  height: 50px;
}
.plane img {
  width: 100%;
  height: 100%;
}
</style>