<template>
  <div class="game-container" @keydown.prevent="handleKeyDown" tabindex="0" ref="gameContainer">
    <div class="game-layout">
      <div class="game-area" ref="gameArea">
        <Plane v-for="plane in planes" :key="`plane-${plane.id}`" :plane="plane" />
        <Bullet v-for="bullet in bullets" :key="`bullet-${bullet.id}`" :bullet="bullet" />
        <Cannon 
          v-for="(player, index) in activePlayers" 
          :key="`cannon-${index}`" 
          :angle="player.cannonAngle" 
          :playerIndex="index"
          :isSinglePlayer="isSinglePlayer"
        />
        <div v-if="isMobile" class="mobile-controls">
          <div class="direction-wheel" @touchstart="startRotation" @touchmove="rotate" @touchend="endRotation">
            <div class="wheel-pointer" :style="{ transform: `rotate(${players[0].cannonAngle}deg)` }"></div>
          </div>
          <button class="fire-button" @touchstart="fireBullet(0)">发射</button>
        </div>
      </div>
      <div class="game-info">
        <div v-for="(player, index) in activePlayers" :key="`player-${index}`" class="player-info">
          <h3>玩家 {{ index + 1 }}</h3>
          <p>得分: {{ player.score }}</p>
          <p>炮弹速度: {{ player.bulletSpeed }}</p>
          <p>炮弹类型: {{ player.bulletType }}</p>
		  <p>剩余炮弹: {{ player.remainingBullets }}</p>  <!-- 新增这行 -->
          <button @click="changeBulletType(index)">切换炮弹类型</button>
          <button @click="increaseBulletSpeed(index)">增加炮弹速度</button>
          <button @click="decreaseBulletSpeed(index)">减少炮弹速度</button>
        </div>
        <p class="difficulty">难度: {{ difficulty.toFixed(1) }}</p>
        <div class="game-controls">
          <button @click="togglePause">{{ isPaused ? '继续' : '暂停' }}</button>
          <button @click="endGame">结束游戏</button>
        </div>
      </div>
    </div>
    <ScoreBoard v-if="gameOver" :players="activePlayers" @restart="restartGame" />
  </div>
</template>

<script>
import Plane from '@/components/Plane.vue'
import Bullet from '@/components/Bullet.vue'
import Cannon from '@/components/Cannon.vue'
import ScoreBoard from '@/components/ScoreBoard.vue'
import { playSound } from '@/utils/gameUtils'

let idCounter = 0;

function generateUniqueId() {
  return `${Date.now()}-${idCounter++}`;
}

const PLANE_TYPES = Object.freeze(['fast', 'medium', 'slow']);

export default {
  name: 'Game',
  components: {
    Plane,
    Bullet,
    Cannon,
    ScoreBoard,
  },
  data() {
    return {
      planes: [],
      bullets: [],
      players: [
        { cannonAngle: 0, score: 0, highScore: 0, bulletSpeed: 10, bulletType: 'normal', remainingBullets: 200 },
        { cannonAngle: 0, score: 0, highScore: 0, bulletSpeed: 10, bulletType: 'normal', remainingBullets: 200 },
      ],
      gameInterval: null,
      isPaused: false,
      gameOver: false,
      difficulty: 1,
      backgroundMusic: null,
      isSinglePlayer: true,
      lastUpdateTime: 0,
      isMobile: false,
      wheelTouching: false,
      lastTouchAngle: 0,
      bulletTimer: null,
    }
  },
  computed: {
    activePlayers() {
      return this.isSinglePlayer ? [this.players[0]] : this.players
    },
  },
  methods: {
    startGame() {
      this.lastUpdateTime = performance.now()
      this.gameLoop()
      this.spawnPlane()
      this.backgroundMusic = playSound('background', true)
      this.bulletTimer = setInterval(() => {
        this.players.forEach(player => {
          if (player.remainingBullets < 200) {
            player.remainingBullets++;
          }
        });
      }, 5000);
    },
    endGame() {
      cancelAnimationFrame(this.gameInterval)
      this.updateHighScores()
      this.gameOver = true
      this.planes = []
      if (this.backgroundMusic) {
        this.backgroundMusic.pause()
      }
      clearInterval(this.bulletTimer);
    },
    restartGame() {
      this.planes = []
      this.bullets = []
      this.players.forEach(player => {
        player.score = 0
        player.cannonAngle = 0
        player.bulletSpeed = 10
        player.bulletType = 'normal'
        player.remainingBullets = 200
      })
      this.difficulty = 1
      this.gameOver = false
      this.startGame()
    },
    gameLoop(currentTime) {
      if (this.isPaused || this.gameOver) {
        this.lastUpdateTime = currentTime
        this.gameInterval = requestAnimationFrame(this.gameLoop)
        return
      }

      const deltaTime = currentTime - this.lastUpdateTime
      if (deltaTime >= 16) { // 约60fps
        this.lastUpdateTime = currentTime
        this.movePlanes(deltaTime / 1000)
        this.moveBullets(deltaTime / 1000)
        this.checkCollisions()
        this.increaseDifficulty()
      }

      this.gameInterval = requestAnimationFrame(this.gameLoop)
    },
    spawnPlane() {
      if (this.gameOver || this.isPaused) return
      const planeType = PLANE_TYPES[Math.floor(Math.random() * PLANE_TYPES.length)]
      const direction = Math.random() < 0.5 ? 'left' : 'right'
      
      const cannonHeight = 60
      const minY = 0
      const maxY = this.$refs.gameArea.clientHeight - cannonHeight - 50
      
      const plane = {
        id: generateUniqueId(),
        x: direction === 'left' ? 0 : this.$refs.gameArea.clientWidth,
        y: Math.random() * (maxY - minY) + minY,
        speed: this.getPlaneSpeed(planeType) * (direction === 'left' ? 1 : -1),
        type: planeType,
        direction: direction,
      }
      this.planes.push(plane)
      setTimeout(this.spawnPlane, 2000 / this.difficulty)
    },
    getPlaneSpeed(type) {
      switch (type) {
        case 'fast': return 3 * this.difficulty
        case 'medium': return 2 * this.difficulty
        case 'slow': return 1 * this.difficulty
        default: return 2 * this.difficulty
      }
    },
    movePlanes(deltaTime) {
      this.planes = this.planes.filter(plane => {
        plane.x += plane.speed * deltaTime * 60
        if ((plane.direction === 'left' && plane.x > this.$refs.gameArea.clientWidth) || 
            (plane.direction === 'right' && plane.x < 0)) {
          if (!this.isSinglePlayer) {
            this.players[1].score += this.getPlaneScore(plane.type)
          }
          return false
        }
        return true
      })
    },
    fireBullet(playerIndex) {
      const player = this.players[playerIndex]
      if (player.remainingBullets <= 0) {
        console.log('Player ' + (playerIndex + 1) + ' has no bullets left');
        return;
      }
      
      const gameArea = this.$refs.gameArea
      let cannonPosition
    
      if (this.isSinglePlayer) {
        cannonPosition = { 
          x: gameArea.clientWidth / 2, 
          y: gameArea.clientHeight - 50
        }
      } else {
        cannonPosition = playerIndex === 0 ? 
          { x: gameArea.clientWidth - 37.5, y: gameArea.clientHeight - 50 } :
          { x: 37.5, y: gameArea.clientHeight - 50 }
      }
    
      const radians = player.cannonAngle * Math.PI / 180
      const bulletX = cannonPosition.x - Math.sin(radians) * 25
      const bulletY = cannonPosition.y - Math.cos(radians) * 25
    
      const bullet = {
        id: generateUniqueId(),
        x: bulletX,
        y: bulletY,
        angle: player.cannonAngle,
        speed: player.bulletSpeed,
        type: player.bulletType,
        playerIndex,
        initialSpeed: player.bulletSpeed,
        isTracking: false,
      }
    
      console.log('Firing bullet:', bullet);
      this.bullets.push(bullet)
      player.remainingBullets--;
      playSound('shoot')
    },
    findNearestPlane(bullet) {
      let nearestPlane = null
      let minDistance = Infinity
    
      for (const plane of this.planes) {
        const dx = plane.x - bullet.x
        const dy = plane.y - bullet.y
        const distance = Math.sqrt(dx * dx + dy * dy)
    
        if (distance < minDistance) {
          minDistance = distance
          nearestPlane = plane
        }
      }
    
      return nearestPlane
    },
    moveBullets(deltaTime) {
      this.bullets = this.bullets.filter(bullet => {
        const distance = bullet.speed * deltaTime * 60;
		if (bullet.type === 'smart') {
		          const nearestPlane = this.findNearestPlane(bullet);
		          if (nearestPlane && this.getDistance(bullet, nearestPlane) < 100) {
		            const dx = nearestPlane.x - bullet.x;
		            const dy = nearestPlane.y - bullet.y;
		            bullet.angle = Math.atan2(dy, dx) * 180 / Math.PI;
		            
		            const radians = bullet.angle * Math.PI / 180;
		            bullet.x += Math.cos(radians) * distance;
		            bullet.y += Math.sin(radians) * distance;
		          } else {
		            const radians = bullet.angle * Math.PI / 180;
		            bullet.x -= Math.sin(radians) * distance;
		            bullet.y -= Math.cos(radians) * distance;
		          }
		        } else {
		          const radians = bullet.angle * Math.PI / 180;
		          bullet.x -= Math.sin(radians) * distance;
		          bullet.y -= Math.cos(radians) * distance;
		        }
		        
		        return !(bullet.x < 0 || bullet.x > this.$refs.gameArea.clientWidth || 
		                 bullet.y < 0 || bullet.y > this.$refs.gameArea.clientHeight);
		      });
		    },
		    checkCollisions() {
		      this.bullets = this.bullets.filter(bullet => {
		        let bulletRemoved = false
		        this.planes = this.planes.filter(plane => {
		          if (this.isCollision(bullet, plane)) {
		            const player = this.players[bullet.playerIndex]
		            let score = this.getPlaneScore(plane.type)
		            if (bullet.type === 'smart') {
		              score = Math.round(score / 2)
		            }
		            player.score += score
		            bulletRemoved = true
		            playSound('explosion')
		            return false
		          }
		          return true
		        })
		        return !bulletRemoved
		      })
		    },
		    isCollision(bullet, plane) {
		      const dx = bullet.x - plane.x
		      const dy = bullet.y - plane.y
		      const distance = Math.sqrt(dx * dx + dy * dy)
		      return distance < 25
		    },
		    getPlaneScore(type) {
		      switch (type) {
		        case 'fast': return 30
		        case 'medium': return 20
		        case 'slow': return 10
		        default: return 10
		      }
		    },
		    updateHighScores() {
		      this.players.forEach(player => {
		        if (player.score > player.highScore) {
		          player.highScore = player.score
		        }
		      })
		    },
		    togglePause() {
		      this.isPaused = !this.isPaused
		      if (this.backgroundMusic) {
		        if (this.isPaused) {
		          this.backgroundMusic.pause()
		        } else {
		          this.backgroundMusic.play()
		        }
		      }
		    },
		    increaseDifficulty() {
		      if (this.players.some(player => player.score > this.difficulty * 100)) {
		        this.difficulty = Math.min(this.difficulty + 0.1, 4.5)
		      }
		    },
		    handleKeyDown(e) {
		      if (this.gameOver) return
		      
		      // Player 1 controls
		      if (e.key === 'ArrowUp') {
		        this.players[0].cannonAngle = (this.players[0].cannonAngle + 5) % 360
		      } else if (e.key === 'ArrowDown') {
		        this.players[0].cannonAngle = (this.players[0].cannonAngle - 5 + 360) % 360
		      } else if (e.key === '/') {
		        this.fireBullet(0)
		      }
		
		      // Player 2 controls (only in two-player mode)
		      if (!this.isSinglePlayer) {
		        if (e.key === 'w') {
		          this.players[1].cannonAngle = (this.players[1].cannonAngle + 5) % 360
		        } else if (e.key === 's') {
		          this.players[1].cannonAngle = (this.players[1].cannonAngle - 5 + 360) % 360
		        } else if (e.key === 'd') {
		          this.fireBullet(1)
		        }
		      }
		
		      // General controls
		      if (e.key === 'p') {
		        this.togglePause()
		      }
		    },
		    handleResize() {
		      const gameArea = this.$refs.gameArea;
		      const aspectRatio = 4/3;
		      const maxWidth = Math.min(window.innerWidth * 0.9, 800);
		      const maxHeight = window.innerHeight * 0.8;
		
		      let width = maxWidth;
		      let height = width / aspectRatio;
		
		      if (height > maxHeight) {
		        height = maxHeight;
		        width = height * aspectRatio;
		      }
		
		      gameArea.style.width = `${width}px`;
		      gameArea.style.height = `${height}px`;
		    },
		    getDistance(obj1, obj2) {
		      const dx = obj1.x - obj2.x
		      const dy = obj1.y - obj2.y
		      return Math.sqrt(dx * dx + dy * dy)
		    },
		    changeBulletType(playerIndex) {
		      const player = this.players[playerIndex];
		      player.bulletType = player.bulletType === 'normal' ? 'smart' : 'normal';
		      console.log(`Player ${playerIndex + 1} bullet type changed to:`, player.bulletType);
		    },
		    increaseBulletSpeed(playerIndex) {
		      const player = this.players[playerIndex];
		      player.bulletSpeed = Math.min(player.bulletSpeed + 1, 20);
		      console.log(`Player ${playerIndex + 1} bullet speed increased to:`, player.bulletSpeed);
		    },
		    decreaseBulletSpeed(playerIndex) {
		      const player = this.players[playerIndex];
		      player.bulletSpeed = Math.max(player.bulletSpeed - 1, 5);
		      console.log(`Player ${playerIndex + 1} bullet speed decreased to:`, player.bulletSpeed);
		    },
		    startRotation(event) {
		      this.wheelTouching = true;
		      this.rotate(event);
		    },
		    rotate(event) {
		      if (!this.wheelTouching) return;
		      
		      const touch = event.touches[0];
		      const wheel = event.target;
		      const rect = wheel.getBoundingClientRect();
		      const centerX = rect.left + rect.width / 2;
		      const centerY = rect.top + rect.height / 2;
		      
		      const angle = Math.atan2(touch.clientY - centerY, touch.clientX - centerX);
		      const degrees = (angle * (180 / Math.PI) + 90 + 360) % 360;
		      
		      this.players[0].cannonAngle = degrees;
		      this.lastTouchAngle = degrees;
		    },
		    endRotation() {
		      this.wheelTouching = false;
		    },
		    checkMobile() {
		      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		    },
		  },
		  created() {
		    this.isSinglePlayer = this.$route.query.mode === 'single';
		    this.checkMobile();
		  },
		  mounted() {
		    if (!this.isMobile) {
		      window.addEventListener('keydown', this.handleKeyDown);
		    }
		    window.addEventListener('resize', this.handleResize);
		    this.$nextTick(() => {
		      this.handleResize();
		      this.startGame();
		    });
		    this.$refs.gameContainer.focus();
		  },
		  beforeDestroy() {
		      if (!this.isMobile) {
		        window.removeEventListener('keydown', this.handleKeyDown);
		      }
		      window.removeEventListener('resize', this.handleResize);
		      cancelAnimationFrame(this.gameInterval);
		      if (this.backgroundMusic) {
		        this.backgroundMusic.pause();
		      }
		      clearInterval(this.bulletTimer);
		    },
		  }
		  </script>
        
        <style scoped>
        .game-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100vh;
          overflow: hidden;
          padding: 10px;
          box-sizing: border-box;
        }
        
        .game-layout {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 800px;
          height: 100%;
        }
        
        .game-area {
          flex: 1;
          border: 1px solid black;
          position: relative;
          overflow: hidden;
          background-color: #87CEEB;
          aspect-ratio: 4 / 3;
        }
        
        .game-info {
          padding: 10px;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 10px;
          margin-top: 10px;
          overflow-y: auto;
        }
        
        .player-info {
          margin-bottom: 10px;
          padding: 5px;
          background-color: #f0f0f0;
          border-radius: 5px;
        }
        
        .difficulty {
          font-weight: bold;
          margin-top: 10px;
        }
        
        .game-controls {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
        }
        
        .game-controls button,
        .player-info button {
          margin: 2px;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #4CAF50;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        
        .game-controls button:hover,
        .player-info button:hover {
          background-color: #45a049;
        }
        
        .mobile-controls {
          position: absolute;
          bottom: 10px;
          left: 10px;
          right: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .direction-wheel {
          width: 100px;
          height: 100px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          position: relative;
        }
        
        .wheel-pointer {
          width: 4px;
          height: 40px;
          background-color: red;
          position: absolute;
          left: 50%;
          bottom: 50%;
          transform-origin: bottom center;
        }
        
        .fire-button {
          width: 80px;
          height: 80px;
          background-color: red;
          border: none;
          border-radius: 50%;
          color: white;
          font-size: 16px;
          font-weight: bold;
        }
        
        @media (min-width: 768px) {
          .game-layout {
            flex-direction: row;
          }
        
          .game-area {
            flex: 2;
          }
        
          .game-info {
            flex: 1;
            margin-top: 0;
            margin-left: 10px;
          }
        }
        </style>