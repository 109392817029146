<template>
  <div class="cannon" :style="cannonStyle">
    <img src="@/assets/cannon.png" alt="Cannon" />
  </div>
</template>

<script>
export default {
  name: 'Cannon',
  props: ['angle', 'playerIndex', 'isSinglePlayer'],
  computed: {
    cannonStyle() {
      const baseStyle = {
        transform: `rotate(${-this.angle}deg)`,
        bottom: '0px',
      }

      if (this.isSinglePlayer) {
        baseStyle.left = 'calc(50% - 12.5px)'  // 调整为炮台宽度的一半
      } else {
        baseStyle[this.playerIndex === 0 ? 'right' : 'left'] = '12.5px'  // 稍微向内移动
      }

      return baseStyle
    },
  },
}
</script>

<style scoped>
.cannon {
  position: absolute;
  width: 25px;
  height: 50px;
  transform-origin: center bottom;
}
.cannon img {
  width: 100%;
  height: 100%;
}
</style>